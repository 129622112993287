import React from "react";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import BlogDetail from "../Components/Blog";
import * as Blog from "../../blog/BlogContext";
import * as Ant from "antd";

class BlogEditorPage extends React.Component {
  render() {
    const { user, id } = this.props;

    if (!user) {
      return (
        <div
          style={{
            paddingTop: 20,
            flex: 1,
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Ant.Spin />
        </div>
      );
    }

    return (
      <div style={{ padding: "0px 15px 15px 15px" }}>
        <BlogDetail
          isEditor={true}
          articleId={id}
          onBack={() => {
            // bypass
          }}
        />
      </div>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      user: Selectors.getLoginUser(state),
      id: Selectors.getQueryParams(ownProps).id,
    }),
    ActionCreator
  )(BlogEditorPage)
);
